/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import CustomerCard from '../components/CustomerCard';
import customerData from '../data/customer-data';
import SplitSection from '../components/SplitSection';
import BAO from '../svg/LogoBAO';
import ArrowScroll from '../svg/arrowScroll';

export default class Nosotros extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="-my-32 md:-my-2" />
        <SplitSection
          reverseOrder
          primarySlot={
            <div className="lg:pl-32 md xl:pl-20 my-20">
              {/*
              <h2 className="text-3xl font-semibold leading-tight">Bit As One</h2>
              */}
              <p className="font-medium text-lg font-light md:text-justify leading-relaxed">
                Somos Bit as One, una pre-empresa chilena conformada por estudiantes de Ingeniería
                Civil Informática de la Universidad Técnica Federico Santa María. Nuestro objetivo
                es desarrollar soluciones tecnológicas que ayuden a satisfacer necesidades básicas
                de las personas, generando cambios en campos como salud y educación.
              </p>
              {/*<p className=" text-lg font-light md:text-justify leading-relaxed">
                En Abril del 2020 comenzamos con el desarrollo de EufoniApp, nuestra primera
                aplicación que busca apoyar el trabajo de fonoaudiólogas y fonoaudiólogos, la cual
                además participará en la XXVIII Feria de Software a realizarse el 17 de diciembre
                del mismo año. Este es solo el inicio de lo que será una gran etapa como Bit as One.
            </p> */}
            </div>
          }
          secondarySlot={
            <div className="mx-auto md:w-4/5 ">
              <BAO />
            </div>
          }
        />
        <div className="container hidden mx-auto px-8 md:flex">
          <div className="mx-auto">
            
          </div>
        </div>
        <section id="testimonials" className="py-10 md:my-24 lg:py-20">
          <div className="container mx-auto">
            <h2 className="text-3xl text-center lg:text-5xl font-semibold">Equipo</h2>
            <div className="flex flex-col mt-5 md:flex-row md:-mx-3">
              {customerData.map(customer => (
                <div key={customer.customerName} className="flex-1 px-3">
                  <CustomerCard customer={customer} />
                </div>
              ))}
            </div>
          </div>
        </section>

        <Footer />
      </>
    );
  }
}
